import React from "react";
import { Link } from "react-router-dom";

import hrVisual from "../../img/hr-visual.png";

const Banner = () => {
	return (
		<section className="banner">
			<div className="container">
				<div className="content-left">
					<img src={hrVisual} alt="hr-visual" />
				</div>
				<div className="content-right">
					<h2 className="black">
						<span id="banner_title_1"></span>{" "}
						<span className="fw-black" id="banner_title_2"></span>
					</h2>
					<span
						className="d-block paragraph black"
						id="banner_paragraph"
					></span>
					<Link
						to=""
						target="_blank"
						className="btn btn-blue"
						id="banner_cta"
					></Link>
				</div>
			</div>
		</section>
	);
};

export default Banner;
