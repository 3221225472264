import React from "react";
import ReactDOM from "react-dom";

import "./style.scss";

const Backdrop = (props) => {
	return ReactDOM.createPortal(
		<div className="backdrop" onClick={props.onClick}></div>,
		document.body
	);
};

export default Backdrop;
