import React, { useState } from "react";
import { Link } from "react-router-dom";

import Modal from "../Modal";

import Logo from "../../img/logo-zucchetti-white.svg";
import IcoFb from "../../img/ico-fb.svg";
import IcoIg from "../../img/ico-ig.svg";
import IcoXTw from "../../img/ico-x-twitter.svg";
import IcoYt from "../../img/ico-yt.svg";
import IcoLi from "../../img/ico-li.svg";

const Footer = () => {
	const [openCreditModal, setOpenCreditModal] = useState(false);

	const closeCreditModal = () => setOpenCreditModal(false);

	return (
		<section>
			<Modal
				onCancel={closeCreditModal}
				header="Credits"
				show={openCreditModal}
			>
				<h4>Immagini utilizzate</h4>
				<ul className="credits__list">
					<li>
						<Link
							to={
								"https://www.freepik.com/free-photo/teamwork-concept-with-architects_1358496.htm"
							}
							target="_blank"
						>
							Immagine di freepik
						</Link>
						&nbsp;su Freepik
					</li>
					<li>
						<Link
							to={
								"https://www.freepik.com/free-photo/business-concept-portrait-handsome-business-man-playing-digital-tablet-with-smiling-confident-face-white-background-copy-space_27103908.htm"
							}
							target="_blank"
						>
							Immagine di benzoix
						</Link>
						&nbsp;su Freepik
					</li>
				</ul>
			</Modal>
			<footer>
				<div className="container">
					<div className="footer-logo-container">
						<a href="https://www.zucchetti.it/">
							<img src={Logo} className="footer-logo" alt="logo-zucchetti" />
						</a>
					</div>
					<div className="footer-iva-container">
						<span className="white small">© 2017 - 2021 Zucchetti s.p.a.</span>
						<span className="white small">P.IVA 05006900962</span>
						<span className="white small" id="footer_rights"></span>
						<span className="white small">-</span>
						<Link
							className="white small "
							onClick={() => setOpenCreditModal(true)}
						>
							Credits
						</Link>
					</div>
					<div className="footer-social-container">
						<Link
							to={"https://www.facebook.com/ZucchettiSoftware"}
							target="_blank"
						>
							<img src={IcoFb} className="footer-social" alt="facebook" />
						</Link>
						<Link
							to={"https://www.instagram.com/zucchetti_official"}
							target="_blank"
						>
							<img src={IcoIg} className="footer-social" alt="instagram" />
						</Link>
						<Link to={"https://twitter.com/ZucchettiSpa"} target="_blank">
							<img src={IcoXTw} className="footer-social" alt="twitter" />
						</Link>
						<Link
							to={"https://www.youtube.com/channel/UCmMDzalD-QDO_7aqnQc9lyw"}
							target="_blank"
						>
							<img src={IcoYt} className="footer-social" alt="youtube" />
						</Link>
						<Link
							to={"https://www.linkedin.com/company/zucchetti"}
							target="_blank"
						>
							<img src={IcoLi} className="footer-social" alt="linkedin" />
						</Link>
					</div>
				</div>
			</footer>
		</section>
	);
};

export default Footer;
