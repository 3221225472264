import { useState, useEffect, useContext, createContext } from "react";
const AppContext = createContext();

const AppProvider = ({ children }) => {
	const [mainElem, setMainElem] = useState(null);

	return (
		<AppContext.Provider
			value={{
				mainElem,
				setMainElem,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

const useGlobalContext = () => {
	return useContext(AppContext);
};

export { AppProvider, useGlobalContext };
