import React from "react";
import { Link } from "react-router-dom";

// Per id_url e id_text vedi lang/*.json
// id_url è la key dell'oggetto "url" alla quale corrisponde il link di destinazione della card
// id_text è la key dell'oggetto "text" alla quale corrisponde la didascalia della card

const Card = ({ title, imageUrl, id_url, id_text }) => {
	return (
		<React.Fragment>
			<Link to="" target="_blank" className="card" id={id_url}>
				<img className="card-image d-block" src={imageUrl} alt={id_text} />
				<div className="card-body">
					<h3 className="black">{title}</h3>
					<span className="small gray d-block" id={id_text}></span>
				</div>
			</Link>
		</React.Fragment>
	);
};

export default Card;
