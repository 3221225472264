import React from "react";
import ReactDOM from "react-dom";
import Backdrop from "../Backdrop";
import "./style.scss";

const ModalOverlay = (props) => {
	const content = (
		<div id="modal-hook">
			<div className={`modal ${props.className || ""}`} style={props.style}>
				<header className={`modal__header ${props.headerClass || ""}`}>
					<h2>{props.header}</h2>
				</header>
				<form
					onSubmit={
						props.onSubmit ? props.onSubmit : (event) => event.preventDefault
					}
				>
					<div className={`modal__content ${props.contentClass || ""}`}>
						{props.children}
					</div>
					<div className={`modal__footer ${props.footerClass || ""}`}>
						{props.footer}
					</div>
				</form>
			</div>
		</div>
	);
	return ReactDOM.createPortal(content, document.body);
};

const Modal = (props) => {
	return (
		<React.Fragment>
			{props.show && <Backdrop onClick={props.onCancel} />}
			{props.show && <ModalOverlay {...props} />}
		</React.Fragment>
	);
};

export default Modal;
