import React from "react";

import bgHeroGradient from "../../img/bg-hero-gradient.jpg";
import hrTestimonial from "../../img/hr-testimonial.png";
import cardConsulente from "../../img/card-consulente.jpg";
import cardMedico from "../../img/card-medico.jpg";
import Card from "../Card";

const Hero = () => {
	return (
		<section className="hero">
			<img src={bgHeroGradient} className="bg-hero" alt="bg-hero" />
			<img
				src={hrTestimonial}
				className="hr-testimonial"
				alt="hr-testimonial"
			/>
			<div className="cta-scroll">
				<span>SCROLL</span>
				<div className="c-scrolldown">
					<div className="c-line"></div>
				</div>
			</div>
			<div className="container d-flex flex-col">
				<header>
					<h1 className="white">
						<span id="hero_title"></span>{" "}
						<span id="hero_subtitle" className="fw-black"></span>
					</h1>
					<span className="d-block paragraph white" id="hero_paragraph"></span>
				</header>
				<div className="swiper-container">
					<div className="cta-swipe">
						<span>SCROLL</span>
						<div className="c-scrollright">
							<div className="c-line"></div>
						</div>
					</div>
					<div className="swiper">
						<Card
							title={"ZSafePro Consulenti"}
							imageUrl={cardConsulente}
							id_url={"url_zsafepro_cons"}
							id_text={"hero_card_zsafepro_cons_text"}
						/>
						<Card
							title={"ZSafePro Medici del lavoro"}
							imageUrl={cardMedico}
							id_url={"url_zsafepro_med"}
							id_text={"hero_card_zsafepro_med_text"}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
