import React from "react";
import "./index.scss";

import Hero from "../../components/Hero";
import Banner from "../../components/Banner";

const Home = () => {
	return (
		<>
			<Hero />
			<Banner />
		</>
	);
};

export default Home;
