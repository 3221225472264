/* TRANSLATION */
// Creo le singole funzioni, ognuna ricarica il json, rilancia la funzione per riscrivere il testo e cancella eventuali card
const lang = (mylang) => {
	const jsonLang = require(`../lang/${mylang}.json`);
	writeText(jsonLang);
	// document.querySelector(".card").style.display = "inline-block";
};

// funzione che riscrive testi e url dei link, viene richiamata in ogni funzione langIt etc...
const writeText = (data) => {
	const paths = Object.keys(data.url);
	paths.forEach(function (path) {
		const path_element = document.getElementById(path);
		if (path_element) {
			path_element.setAttribute("href", data.url[path]);
		}
	});

	const voices = Object.keys(data.text);
	voices.forEach(function (voice) {
		const voice_element = document.getElementById(voice);
		if (voice_element) {
			voice_element.innerText = data.text[voice];
		}
	});
};

// funzione che rileva la lingua del browser e imposta la lingua dei testi
const load = () => {
	const language = window.navigator.language;
	const langSelectDOMElem = document.querySelector("#langSelect");

	lang("it"); // riga da eliminare se si riattiva la scelta della lingua

	// if (language === "it-IT" || language === "it") {
	// 	lang("it");
	// 	langSelectDOMElem.value = "it";
	// } else if (language === "de-DE" || language === "de") {
	// 	lang("de");
	// 	langSelectDOMElem.value = "de";
	// 	document.querySelector("#url_infinity").style.display = "none";
	// } else if (lang === "es-ES" || language === "es") {
	// 	lang("es");
	// 	langSelectDOMElem.value = "es";
	// 	document.querySelector("#url_infinity").style.display = "none";
	// } else {
	// 	lang("en");
	// 	langSelectDOMElem.value = "en";
	// }
};

/* SELECT */

// A seconda del value della option eseguo la relativa funzione, la funzione viene lanciata onchange direttamente dal tag html.
// se il value è  ES o DE nascondo anche la card infinity
const changeLang = (langSelectValue) => {
	lang(langSelectValue);
	if (langSelectValue === "es" || langSelectValue === "de") {
		document.querySelector("#url_infinity").style.display = "none";
	}
};

export { load, changeLang };
