import React from "react";
import { createRoot } from "react-dom/client";
import "./scss/style.css";
import App from "./App";
import { AppProvider } from "./context";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
	<AppProvider>
		<App />
	</AppProvider>
);
