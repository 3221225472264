import React, { useEffect, useState } from "react";

import { changeLang } from "../../utils/translation";

import HrLogoW from "../../img/logo-zucchetti-hr-white.svg";
import HrLogoC from "../../img/logo-zucchetti-hr-color.svg";
import { useGlobalContext } from "../../context";

const Navbar = () => {
	const { mainElem } = useGlobalContext();
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		if (mainElem?.current) {
			const main = mainElem?.current;
			const scrollEvent = (e) => {
				if (e.target.scrollTop > 50) {
					setIsScrolled(true);
				} else {
					setIsScrolled(false);
				}
			};

			main.addEventListener("scroll", scrollEvent);
			return () => main.removeEventListener("scroll", scrollEvent);
		}
	});

	const selectedLangHandler = (e) => {
		changeLang(e.target.value);
	};

	return (
		<nav id="navbar" className={isScrolled ? "scrolled" : ""}>
			<div className="container">
				<ul>
					<li>
						<figure className="hr-logo">
							<img
								className="hr-logo-white"
								src={HrLogoW}
								alt="hr-logo-white"
							/>
							<img
								className="hr-logo-color"
								src={HrLogoC}
								alt="hr-logo-color"
							/>
						</figure>
					</li>
					{/* <li>
						<div className="select-style">
							<select id="langSelect" onChange={selectedLangHandler}>
								<option value="it">Italiano</option>
								<option value="en">English</option>
								<option value="es">Español</option>
								<option value="de">Deutsch</option>
							</select>
						</div>
					</li> */}
				</ul>
			</div>
		</nav>
	);
};

export default Navbar;
