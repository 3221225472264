import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import { load } from "./utils/translation";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { useGlobalContext } from "./context";
import Home from "./pages/Home";

function App() {
	const { setMainElem } = useGlobalContext();

	const refMain = useRef(null);

	useEffect(() => {
		load();
	}, []);

	useEffect(() => setMainElem(refMain), [refMain, setMainElem]);

	return (
		<Router>
			<Navbar />
			<main ref={refMain}>
				{/* <Routes>
					{Object.values(routes).map(({ path, element, label }) => {
						return <Route path={path} element={element} key={label} />;
					})}
				</Routes> */}
				<Home />
				<Footer />
			</main>
		</Router>
	);
}

export default App;
